<template>
  <div>
    <div class="flix-form-group">
      <div class="flix-form-group">
        <h3 class="flix-html-h4">
          {{ $tc("message.appointments", 1) }} {{ number }}: {{ $t("message.begin") }}
        </h3>
        <dayPicker :date="startDay" :callback="function(date){ setStartDay(date);  }"/>
      </div>
      <h3 class="flix-html-h4">
        {{ $t("message.add", {name: $tc("message.appointments", 1) + " " + (number) + ': ' + $t("message.end")}) }}
      </h3>
      <toggle class="flix-form-group" :data="openEnd" :key="openEnd" :onChange="function(ret) { setOpenEnd(ret); }" :settings="{bottomButton: false}">
        <template v-slot:0>{{ $t('message.yes') }}</template>
        <template v-slot:1>{{ $t('message.no') }}</template>
      </toggle>
    </div>
    <div class="flix-form-group" v-if="openEnd === $t('message.yes')">
      <dayPicker :date="endDay" :callback="function(date){ setEndDay(date) }"/>
    </div>
    <div class="flix-text-danger" v-if="error"><flixIcon id="warning-sign" /> {{ $t('message.pleaseCheckAppointmentDate') }}</div>
  </div>
</template>
<script>
export default {
  components: {
    dayPicker () { return import('@/components/assistent/components/assets/daypicker') }
  },
  props: { data: Object, callback: Function, number: Number, title: String },
  data () {
    return {
      startDay: this.getStartDay(),
      endDay: this.getEndDay(),
      openEnd: this.$t('message.no'),
      error: false
    }
  },
  methods: {
    setStartDay (date) {
      this.startDay = date
      if (this.openEnd === this.$t('message.no')) {
        this.endDay = this.startDay
      }
      this.saveData()
    },
    setEndDay (date) {
      this.endDay = date
      this.saveData()
    },
    setOpenEnd (res) {
      this.openEnd = res.target.innerHTML
      this.saveData()
    },
    getOpenEnd () {
      if (this.startDay !== this.endDay) {
        this.openEnd = this.$t('message.yes')
      }
    },
    checkDate () {
      var s = this.startDay.split(' ').join('').split('-').join('').split(':').join('')
      var e = this.endDay.split(' ').join('').split('-').join('').split(':').join('')
      var t = new Date()

      if (s < t.getFullYear() + '' + this.$getNullsBefore(t.getMonth() + 1) + '' + this.$getNullsBefore(t.getDate()) + '' + this.$getNullsBefore(t.getHours()) + '' + this.$getNullsBefore(t.getMinutes())) {
        return false
      }
      if (s * 1 > e * 1) {
        return false
      }
      return true
    },
    saveData () {
      if (this.openEnd === this.$t('message.no')) {
        this.endDay = this.startDay
      }
      this.error = false
      if (this.$createDate(this.startDay).getTime() > this.$createDate(this.endDay).getTime()) {
        this.error = true
      }
      this.callback({
        begin: this.startDay,
        end: this.endDay,
        style: '',
        value: {
          ID: 'fixed',
          name: this.title,
          label: this.title
        }
      }, this.number)
    },
    getStartDay () {
      if (typeof this.data !== 'undefined' && typeof this.data !== 'undefined' && Object.keys(this.data).length && typeof this.data.begin !== 'undefined') {
        return this.data.begin
      }

      var d = new Date()
      d.setDate(d.getDate() + 7)
      d.setHours(10)
      d.setMinutes(0)
      return d.getFullYear() + '-' + this.$getNullsBefore((d.getMonth() + 1)) + '-' + this.$getNullsBefore(d.getDate()) + ' ' + this.$getNullsBefore(d.getHours()) + ':' + this.$getNullsBefore(d.getMinutes())
    },
    getEndDay () {
      if (typeof this.data !== 'undefined' && typeof this.data !== 'undefined' && Object.keys(this.data).length && typeof this.data.end !== 'undefined') {
        return this.data.end
      }

      var d = new Date()
      d.setDate(d.getDate() + 7)
      d.setHours(10)
      d.setMinutes(0)
      return d.getFullYear() + '-' + this.$getNullsBefore((d.getMonth() + 1)) + '-' + this.$getNullsBefore(d.getDate()) + ' ' + this.$getNullsBefore(d.getHours()) + ':' + this.$getNullsBefore(d.getMinutes())
    }
  },
  mounted () {
    this.startDay = this.getStartDay()
    this.endDay = this.getEndDay()
    this.getOpenEnd()
    this.saveData()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
